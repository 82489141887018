.ratio-1x1{
  aspect-ratio: 1/1;
}
.ratio-4x3{
  aspect-ratio: 4/3;
}

.ratio-16x9{
  aspect-ratio: 16/9;
}

.ratio-21x9{
  aspect-ratio: 21/9;
}

.diaporama-ratio-1x1 .figure-slider-bootstrap,.diaporama-ratio-1x1 .figure-slider-accueil{
  aspect-ratio: 1/1;
}
.diaporama-ratio-4x3 .figure-slider-bootstrap,.diaporama-ratio-4x3 .figure-slider-accueil{
  aspect-ratio: 4/3;
}

.diaporama-ratio-16x9 .figure-slider-bootstrap,.diaporama-ratio-16x9 .figure-slider-accueil{
  aspect-ratio: 16/9;
}

.diaporama-ratio-21x9 .figure-slider-bootstrap,.diaporama-ratio-21x9 .figure-slider-accueil{
  aspect-ratio: 21/9;
}

.ratio-3x1{
  aspect-ratio: 3/1;
}

.diaporama-ratio-3x1 .figure-slider-bootstrap,.diaporama-ratio-21x9 .figure-slider-accueil{
  aspect-ratio: 3/1;
}