/* ==========================================================================
	Perso Lydia
	========================================================================== */

  body {
    background: url(../images/homeBG.jpg) no-repeat center fixed;
    background-size: cover;
    // background-color: $body-bg;
    font-family: $font-family-sans-serif;
  }
  
  .main {
    background-color: $body-bg;
    padding-top: 0px;
    min-height: 50vh;
  }
  
  /* ==========================================================================
POPUP
	========================================================================== */
  
  .section-actu {
    margin: 30px auto;
    width: 100%;
    max-width: 1060px;
  
    @media(min-width: $screen-md-min) {
      padding: 0 30px;
    }
  }

  /* ==========================================================================
	MAP
	========================================================================== */
  
  .embed-responsive-map {
    padding-bottom: 40rem;
  }
  
  .block-address {
  
    @media(min-width: $screen-md-min) {
      position: absolute;
      top: 3rem;
      right: 3rem;
      width: 25%;
    }
  }

  /* ==========================================================================
LABEL + BTN
	========================================================================== */
  
  .label {
    font-size: 80%;
  }
  

  /* ==========================================================================
BACKGROUND
	========================================================================== */
 
  @mixin background($imgpath: "",
    $overlay: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))) {
    background: $overlay, url($imgpath) center center no-repeat;
    background-size: cover, cover;
  }
  
  @mixin background2($imgpath: "", $color: rgba(0, 0, 0, 0), $opacity: 0) {
    background: linear-gradient(rgba($color, $opacity), rgba($color, $opacity)),
      url($imgpath) center center no-repeat;
    background-size: cover, cover;
  }
  
  .bg-1 {
    @include background2("../images/charte/bg-1.jpg", #fff, 0.3);
  }

  .bg-2 {
    @include background2("../images/charte/bg-2.jpg", #fff, 0.75);
  }
  
  .bg-gradient-1 {
    @include background("",
      linear-gradient(45deg, $brand-primary, $brand-chart));
  }
  
  .bg-marielouise {
    @include background2("https://picsum.photos/id/1018/1920/1280", #fff, 0);
    border: 3vw solid white;
  }

  .bg-chart{
    background-color: $brand-chart !important;
  }
  
  .bg-second-chart{
    background-color: $brand-second-chart !important;
  }
  
  .bg-info{
    background-color: $brand-info;
  }
  
  .bg-white {
    background-color: white;
  }

  .bg-second{
    background-color: $brand-second;
  }

  /* ==========================================================================
FONTS
	========================================================================== */
  
  //fonts
  
  .font-title {
    font-family: $font-title !important;
  }
  
  .font-small {
    font-family: $font-small !important;
  }

  /* ==========================================================================
QUAND SITE NOIR - DIFFICILE D'AVOIR UN DROPDOWN LISSIBLE
	========================================================================== */

  // .nav-base .list-group-item>a:hover {
  //   background-color: #fff;
  //   color: $brand-chart;
  // }
  
  // .nav-base .list-group-item>a {
  //    color: #fff; 
  // }
  
/* ==========================================================================
BORDER
	========================================================================== */

  .border {
    border: 1px solid $gray-darker !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top {
    border-top: 1px solid $gray-darker !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-end {
    border-right: 1px solid $gray-darker !important;
  }
  
  .border-end-0 {
    border-right: 0 !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid $gray-darker !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-start {
    border-left: 1px solid $gray-darker !important;
  }
  
  .border-start-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: $brand-primary !important;
  }
  
  .border-secondary {
    border-color: $brand-second !important;
  }
  
  .border-success {
    border-color: $brand-success !important;
  }
  
  .border-info {
    border-color: $brand-info !important;
  }
  
  .border-warning {
    border-color: $brand-warning !important;
  }
  
  .border-danger {
    border-color: $brand-danger !important;
  }
  
  .border-chart{
    border-color: $brand-chart important;
  }
  
  .border-second-chart{
    border-color: $brand-second-chart important;
  }
  
  .border-light {
    border-color: $gray-light !important;
  }
  
  .border-dark {
    border-color: $gray-dark !important;
  }
  
  .border-white {
    border-color: #fff !important;
  }
  
  .border-0 {
    border-width: 0 !important;
  }
  
  .border-1 {
    border-width: 1px !important;
  }
  
  .border-2 {
    border-width: 2px !important;
  }
  
  .border-3 {
    border-width: 3px !important;
  }
  
  .border-4 {
    border-width: 4px !important;
  }
  
  .border-5 {
    border-width: 5px !important;
  }
  

  /* ==========================================================================
NAVBAR
	========================================================================== */

  // Navbar Si centrage elements sur deux lignes
 
  
.navbar-nav {
  //@extend .navbar-right; // pour aligner � droite.
  //text-align: center;
  font-family: $font-title !important;
  font-size: $font-size-base;

  @media(min-width: $grid-float-breakpoint) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    float: none;
  }
  
    >li {
  
      >a {
        line-height: 1.6;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          right: 50%;
          height: 0;
          background-color: $brand-primary;
          opacity: 0;
          transition: all ease-in-out .3s;
        }
      }
  
      &:hover>a::after,
      &:active>a::after,
      &:focus>a::after,
      &.active>a::after {
        left: 16px;
        right: 16px;
        height: 3px;
        opacity: 1;
      }
    }
  }
  
  .index-nav {
    @extend .pull-right;
    display: inline-block;
    line-height: $navbar-height;
    padding-right: 10px;
    color: $navbar-default-toggle-icon-bar-bg;
    text-transform: uppercase;
    font-weight: 700;
  
    @media (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  /* ==========================================================================
	FLEX
	========================================================================== */

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  
    @media (max-width: $screen-xs-max) {
      display: inline !important;
    }
  }

  /* ==========================================================================
	LINE HEIGHT
	========================================================================== */

  .lh-1 {
    line-height: 1 !important;
  }
  
  .lh-sm {
    line-height: 1.25 !important;
  }
  
  .lh-base {
    line-height: 1.5 !important;
  }
  
  .lh-lg {
    line-height: 2 !important;
  }

  .divider-img {
    background: url(.../images/charte/divider.png) center no-repeat;
    height: 20px;
    max-width: 400px;
    margin: 10px auto;
  }


  /* ==========================================================================
	OVERFLOW
	========================================================================== */
  .overflow-auto {
    overflow: auto !important;
  }
  
  .overflow-hidden {
    overflow: hidden !important;
  }
  
  .overflow-visible {
    overflow: visible !important;
  }
  
  .overflow-scroll {
    overflow: scroll !important;
  }
  
  /* ==========================================================================
	POSITION
	========================================================================== */

  .position-static {
    position: static !important;
  }
  
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  
  .top-0 {
    top: 0 !important;
  }
  
  .top-50 {
    top: 50% !important;
  }
  
  .top-100 {
    top: 100% !important;
  }
  
  .bottom-0 {
    bottom: 0 !important;
  }
  
  .bottom-50 {
    bottom: 50% !important;
  }
  
  .bottom-100 {
    bottom: 100% !important;
  }
  
  .start-0 {
    left: 0 !important;
  }
  
  .start-50 {
    left: 50% !important;
  }
  
  .start-100 {
    left: 100% !important;
  }
  
  .end-0 {
    right: 0 !important;
  }
  
  .end-50 {
    right: 50% !important;
  }
  
  .end-100 {
    right: 100% !important;
  }

  h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {

  small {
    display: block;
    padding-top: 1rem;
  }
}

.page-header-top {
  margin: 50px 0;
  // position: relative;
  // padding-bottom: 3rem;

  //  &:after{
  // 	content : '';
  // 	position: absolute;
  // 	bottom: 0;
  // 	left:0;
  // 	width: 3rem;
  // 	height: 3px;
  // 	background-color: white;
  // }

  >h1,
  >.h1 {
    margin: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @media (max-width: $screen-sm-max) {
    font-family: $font-family-base;
    font-weight: 900 !important;
  }
}

/* ==========================================================================
	TEXTE
	========================================================================== */

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.text-white {
  color: #fff !important;
}

.text-chart{
color: $brand-chart !important;
}

.text-second-chart{
color: $brand-second-chart !important;
}

.header-index{
  position: absolute;
  z-index: -10;
  margin: 0 auto;
  width: 100%;
  margin-top: 35%;

  @media (max-width: $screen-md-max){
    margin-top: 35%;
  }
}

.carousel-control{
  display: none;
}